.about_container {
  display: grid;
  grid-template-columns: 35% 1fr;
  gap: 2rem;
}

.about_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about_card {
    background: var(--color-light);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition)
}

.about_card:hover{
    background: transparent;
    border-color: var(--color-light);
    cursor: default;
}

.about_icon {
    color: var(--color-primary);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.about_card h5{
    font-size: 0.95rem;
    font-weight:bold
}

.about_card small{
    display: block;
    font-size: 0.8rem;
    line-height: 1.1rem;
    color: var(--color-primary);
}

.about_content p{
    margin: 1rem .5rem 2.6rem;
    color: var(--color-shadow-green);
}
.btn {
    display: inline-block;
}

/* Media Queries */

@media screen and (min-width: 1367px) {
    .about_container {
        grid-template-columns: 1fr;
        
    }
    .about_cards {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1366px) {
    .about_container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .about_cards{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 1.5rem;
    }

    .about_content p {
        margin: 1rem 0 1.5rem;
    }
}

/* small devices */
@media screen and (max-width: 600px) {
    .about_cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    .about_card{
        flex: 0 0 100%
    }
    .about_content {
        text-align: center;
    }
    .about_content p {
        margin: 1.5rem 0;
    }


}