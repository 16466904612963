header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
    position: relative;
}

css-doodle {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
    transform: translate3d(0,0,0);
}

/* CTA */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* Socials */

.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;
    position: absolute;
    left: 0;
    bottom: 4rem;
}



.header_socials::after {
    content: '';
    width: 3px;
    height: 2rem;
    background: var(--color-primary);
}

/* ME */

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 50%;
    max-width: 16rem;
    height: auto;
    position: absolute;
    left: calc(50% - 8rem);
    top: 50%;
    transform: translateY(-50%);
    margin-top: 12rem;
    border-radius: 12rem 12rem 0 0;
    padding: 2rem 0 0 0;
    overflow: hidden;

}

.me img{
    width: 100%;
    height: auto;
}

/* scroll */

.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 6rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

.me, .line1, .line2, .text-light, .cta {
  will-change: transform, opacity;
}


   

/* small devices */
@media screen and (max-width: 600px) {

    .header_socials, .scroll_down {
        display: none;
    }

}