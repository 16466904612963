.container.contact_container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option {
    background: var(--color-shadow-green);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition)
}

.contact_option:hover {
    background: transparent;
    border-color: var(--color-shadow-green)
}

.contact_option-icon{
    font-size: 4rem;
    margin-bottom: 0.5rem;
}

/* FORM */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
    color: black;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1366px) {
    .container.contact_container{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* small devices */
@media screen and (max-width: 600px) {
    .container.contact_container {
        width: var(--container-width-sm);
    }
}