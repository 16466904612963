body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.portfolio_container {
    display: flex;
    flex-direction: column;
    align-items:center;
    max-width: 100%;
    margin: 0 auto;
    gap: 1rem;
}

.portfolio_item{
    width: 90%;
    max-width: 1200px;
    background: var(--color-light);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 20px;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.portfolio_item:hover{
    border-color: var(--color-primary);
    background: transparent;
}


.portfolio_item-image{
    border-radius: 1.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.portfolio_item-image img{
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.portfolio_item:hover .portfolio_item-image img {
    transform: scale(1.1); /* Zoom in the image to 110% of its original size on hover */
}

.portfolio_item-description{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

}

.portfolio_item-description > p {
    margin-bottom: 20px;
}

.portfolio_item h3 {
    margin: 1.2rem 0 2rem;
}




.portfolio_item-cta{
    gap: 1rem;
    display: flex;
    margin-bottom: 1rem;
}



@media screen and (max-width: 1366px) {
    .portfolio_container {
        max-width: 100%;
        padding: 0 20px;
    }

    .portfolio_item{
        width: 95%;
        padding: 15px;
    }
}

/* small devices */
@media screen and (max-width: 600px) {
    .portfolio_container {
        padding: 0 10px;
    }

    .portfolio_item{
        width: 100%;
        padding: 10px;
    }


}