@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,600;1,500&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #d3f2e9;
  --color-light: #7ca8a1;
  --color-shadow-green: #0a7b79;
  --color-primary: rgb(4, 28, 4);
  --color-white: white;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
    font-family: 'Montserrat', sans-serif;
    background: var(--color-bg);
    color: var(--color-darkest-green);
    line-height: 1.7;
    background-image: url(../src/assets/fresh-snow.png);
    background-size: cover;

}

/* -------- GENERAL STYLES ------- */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light)
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light)
}

a {
    color: var(--color-primary);
    transition: var(--transition);
    font-weight: bold;
}

a:hover {
    color: lightslategray;
}

.btn {
    width:max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-primary);
    color: #ffffff;
    border-color: transparent;

}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn-primary:hover{
    background: transparent;
    color: var(--color-primary);
    border: 1px solid var(--color-primary)
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* media queries */
@media screen and (max-width: 1366px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}
/* media queries for small devices */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}


